import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


class IndexPage extends React.Component {
  state = {
    platform: "other"
  }

  componentDidMount(){
    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };

    if(isMobile.Android()){
      this.setState({platform: "android"});
    }

    if(isMobile.iOS()){
      this.setState({platform: "ios"});
    }
  }

  render(){
    return (
      <div>
        <SEO title="Tenta - Send yourself push notifications" description="Let your automated products notify you on your own terms. Get hooked to data, instead of checking for updates on demand."/>
        <div className="hero">
          <div className="heroInner">
            <img src={require("../images/tenta.svg")} style={{width: 100, height: 100}}/>
            <h1 style={{textAlign: "center"}}>send yourself<br/>
            push notifications</h1>
            <div style={{marginBottom: 20}}>
              <p style={{textAlign: "center", marginBottom: 0 }}>Let your automated products notify you on your own terms.</p>
              <p style={{textAlign: "center", marginBottom: 0 }}>Get hooked to data, instead of checking for updates on demand.</p>
            </div>
            <div className="iPhone">
              <div className="notification">
                <div className="notificationHolder">
                  <div className="notificationLeft">
                    <img src={require("../images/icon.png")} style={{width: 20, height: 20}}/>
                  </div>
                  <div className="notificationRight">
                    <div className="notificationTitle">Someone purchased premium!</div>
                    <div className="notificationDesc">Jhon from California purhased the lifetime plan in your SaaS.</div>
                  </div>
                </div>
                <div className="handlebar"></div>
              </div>
              <div className="download">
                {(this.state.platform == "other" || this.state.platform == "ios") &&
                  <a href="https://apps.apple.com/us/app/tenta-self-push-notification/id1553532645">
                    <img src={require("../images/as.svg")} style={{marginRight: 12}}/>
                  </a>
                }
                {(this.state.platform == "other" || this.state.platform == "android") &&
                  <a href="https://play.google.com/store/apps/details?id=org.dreamoriented.tenta&hl=en&gl=US">
                    <img src={require("../images/gp.svg")} style={{width: 195}}/>
                  </a>
                }
              </div>
              <p style={{textAlign: "center", fontSize: "0.85em", opacity: 0.8, marginBottom: 10}}>Free to use, includes reasonable premium <br/>for higher notification volumes</p>
            </div>
          </div>
        </div>
        <Layout noHeader={true}>

          <div style={{marginBottom: 20, display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <p style={{textAlign: "center", maxWidth: 500, margin: "auto", marginBottom: 30}}>Download the app, create an account and send a POST request using your endpoint that we supply.</p>
            <p style={{textAlign: "center",  maxWidth: 500, margin: "auto", marginBottom: 15 }}>Checkout examples in this <a href="https://gist.github.com/btk/3a76a87495c1f95b407d000f2167e7bd.js" target="_blank">gist.</a></p>
            <p style={{textAlign: "center",  maxWidth: 500, margin: "auto", marginBottom: 15 }}>Or see this <a href="https://hoppscotch.io/?method=POST&url=https://tenta.me&path=/NJ7HGR&contentType=application/json&rawParams=%7B%0A%20%20%22title%22:%20%22Your%20notification%20title%22,%0A%20%20%22description%22:%20%22Your%20notification%20description%22,%0A%20%20%22options%22:%20%7B%0A%20%20%20%20%20%20%22detail1%22:%20%22first%20detail%22,%0A%20%20%20%20%20%20%22detail2%22:%20%22second%20detail%22%0A%20%20%7D%0A%7D" target="blank">Postwoman Query</a></p>

          </div>

        </Layout>
      </div>
    )
  }
}

export default IndexPage
