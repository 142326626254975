import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ siteTitle }) => (
  <div className="footer" style={{ paddingTop: 20, paddingBottom: 30}}>
    <div className="footerDivider"></div>

    <img src={require("../images/tenta.svg")} alt="tenta logo" style={{width: 50, marginBottom: 10}}/>
    <p>© {new Date().getFullYear()}, {` `} Dream Oriented Limited</p>
    <div>
    <a href={"https://dreamoriented.org/termsofuse"} target="_blank">Terms of Use</a>
    <a href={"https://dreamoriented.org/privacypolicy"} target="_blank">Privacy Policy</a>
    <a href={"https://dreamoriented.org/licenses"} target="_blank">Licenses</a>
    </div>
  </div>
)

export default Footer
