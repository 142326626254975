import { Link } from "gatsby"
import React from "react"

const Header = ({ siteTitle }) => (
  <header>
    <div className="logo">
    </div>
  </header>
)

export default Header
